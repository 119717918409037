<template>
  <news-table
    :contents="newsList"
    :title="title"
    :is-show-border="false"
    :is-show-filter="true"
    :is-loading="isLoading"
    :years="years"
    created-at-key="published_at"
    @click-item="handleClickItem"
    @change-year="handleChangeYear"
  >
    <pagination
      ref="paginationRef"
      :request="request"
      @load="handleLoad"
      @fetch="handleFetch"
    ></pagination>
  </news-table>
</template>

<script>
  import { defineComponent } from 'vue'
  import NewsTable from '@/components/ContentTable/NewsTable/index.vue'

  import Pagination from '@/components/Pagination/index.vue'
  import { taskForce } from '@/service'

  export default defineComponent({
    name: 'Rosters',
    components: {
      NewsTable,
      Pagination,
    },
    data() {
      return {
        title: '非都市土地使用分區及使用地變更審查作業專責審議小組委員名冊',
        newsList: [],
        isLoading: false,
        taskForce,
        years: [],
        selectedYear: null,
      }
    },
    created() {
      taskForce.getYears().then(({ data }) => {
        this.years = data.map((item) => item.published_roc_year)
      })
    },
    methods: {
      handleLoad(isLoading) {
        this.isLoading = isLoading
      },
      handleFetch(data) {
        this.newsList = data
      },
      handleClickItem(item) {
        this.$router.push({
          name: 'RosterDetail',
          params: { id: item.id || 1 },
        })
      },
      request(config) {
        return taskForce.getRosters({ ...config, year: this.selectedYear })
      },
      handleChangeYear(selectedYear) {
        this.selectedYear = selectedYear
        this.$refs.paginationRef.page = 1
        this.$refs.paginationRef.handleChange()
      },
    },
  })
</script>
